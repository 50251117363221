<template>
  <div class="obody">
    <HomePageTop></HomePageTop>
    <!-- 注册主体 -->
    <div class="logo-box main">
      <!-- logo S -->
      <h2 class="fl">{{ $fanyi("创建新帐户") }}</h2>
      <div style="line-height: 75px" class="fontSize14 colorB4272B">（{{ $fanyi("标记*的请务必填写") }}）</div>
      <!-- logo E -->
    </div>
    <!-- 步骤显示 -->
    <div class="stepGroup">
      <div class="step">
        <div class="item" :class="{ isStep: step == 1}" style="margin-right: 5px">
          <div class="flexAndCenter"><span> 1 </span>
            <div>{{ $fanyi("填写个人信息") }}</div>
          </div>
          <div class="newIsStepBorder" :class="{ isStepBorder: step == 1 }"></div>
        </div>
        <div class="item" :class="{ isStep: step == 2}" style="margin-right: 5px">
          <div class="flexAndCenter"><span>2</span>
            <div>{{ $fanyi("确认个人信息") }}</div>
          </div>
          <div class="newIsStepBorder" :class="{ isStepBorder: step == 2 }"></div>
        </div>
        <div class="item" :class="{ isStep: step == 3}">
          <div class="flexAndCenter"><span>3</span>
            <div>{{ $fanyi("注册完成") }}</div>
          </div>
          <div class="newIsStepBorder" :class="{ isStepBorder: step == 3 }"></div>
        </div>
      </div>
    </div>
    <!-- 第一步页面 -->
    <div class="register" v-if="step == 1">
      <div class="logo">
        <img alt="" src="../../assets/1688/commoditySearchPageLogo.png">
      </div>
      <div class="registerFirstStep">
        <div class="registerHeader flex">
          <el-form
              class="firStepForm"
              :model="formregister"
              :rules="formRules"
              ref="formRef"
              label-position="top">
            <el-form-item prop="username" :label="$fanyi('登录名')">
              <div class="formInput">
                <input v-bind:class="{
                    istrueInput: formInputTrue.username,
                  }"
                       class="ipt"
                       @focus="validateField('username')"
                       @blur="validateField('username')"
                       @input="usernameChannelInputLimit"
                       v-model="formregister.username"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.username===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.username===false">
              </div>
            </el-form-item>
            <el-form-item prop="last_name" :label="$fanyi('姓氏')">
              <div class="formInput">
                <input v-bind:class="{istrueInput: formInputTrue.last_name}" class="ipt"
                       @focus="validateField('last_name')"
                       @blur="validateField('last_name')"
                       v-model="formregister.last_name"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.last_name===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.last_name===false">
              </div>
            </el-form-item>
            <el-form-item prop="realname" :label="$fanyi('名称')">
              <div class="formInput">
                <input v-bind:class="{istrueInput: formInputTrue.realname}" class="ipt"
                       @focus="validateField('realname')"
                       @blur="validateField('realname')"
                       v-model="formregister.realname"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.realname===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.realname===false">
              </div>
            </el-form-item>
            <el-form-item prop="password" :label="$fanyi('密码')">
              <div class="formInput newFormInput">
                <el-input type="password" class="ipt passWordInputBox"
                          v-bind:class="{elistrueInput: formInputTrue.password,}"
                          @focus="validateField('password')"
                          @blur="validateField('password')"
                          @input="passwordChange"
                          autocomplete="new-password"
                          show-password
                          v-model="formregister.password"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.password===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.password===false">
              </div>
            </el-form-item>
            <el-form-item prop="repassword" :label="$fanyi('密码确认')">
              <div class="formInput newFormInput">
                <el-input class="ipt passWordInputBox" type="password"
                          v-bind:class="{elistrueInput: formInputTrue.repassword}"
                          @focus="validateField('repassword')"
                          @blur="validateField('repassword')"
                          autocomplete="new-password"
                          show-password
                          @input="repasswordChange"
                          v-model="formregister.repassword"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.repassword===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.repassword===false">
              </div>
            </el-form-item>
          </el-form>
          <el-form
              class="firStepForm"
              :model="formregister"
              :rules="formRules"
              ref="newFormRef"
              label-position="top"
              style="padding:59px 0 0 110px;">
            <el-form-item :label="$fanyi('公司名')">
              <div class="formInput newFormInput">
                <input class="ipt passWordInputBox" v-model="formregister.company" style="width: 370px!important;"/>
              </div>
            </el-form-item>
            <el-form-item :label="$fanyi('座机')">
              <div class="formInput newFormInput">
                <input class="ipt passWordInputBox" v-model="formregister.tel" style="width: 370px!important;"/>
              </div>
            </el-form-item>
            <el-form-item prop="email" :label="$fanyi('邮箱')" style="position: relative;">
              <div class="emailIconWen">
                <el-tooltip
                    placement="bottom"
                    popper-class="libraryForSeveralTooltip"
                >
                  <!--                      <div>-->
                  <div slot="content">
                    {{ $fanyi('请输入您正常使用的邮箱地址，将用于重要的验证和通知的接收') }}。
                  </div>
                  <div class="iconWen">?</div>
                </el-tooltip>
              </div>
              <div class="formInput">
                <input class="ipt" v-bind:class="{istrueInput: formInputTrue.email}"
                       @focus="validateField('email')"
                       @blur="validateField('email')"
                       v-model="formregister.email" style="width: 370px"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.email===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.email===false">
              </div>
            </el-form-item>
            <el-form-item prop="mobile" :label="$fanyi('手机')" style="position: relative">
              <div class="mobileIconWen">
                <el-tooltip
                    placement="bottom"
                    popper-class="libraryForSeveralTooltip"
                >
                  <!--                      <div>-->
                  <div slot="content">
                    正常に使用する携帯電話番号を入力してください。重要な検証と通知の受信に使用されます。
                  </div>
                  <div class="iconWen">?</div>
                </el-tooltip>
              </div>
              <div class="formInput mobileFormInput flexAndCenter">
                <div class="areaCode">+81</div>
                <input v-bind:class="{istrueInput: formInputTrue.mobile}"
                       class="ipt"
                       @focus="validateField('mobile')"
                       @blur="validateField('mobile')"
                       style="width: 318px"
                       v-model="formregister.mobile"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.mobile===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.mobile===false">
              </div>
            </el-form-item>
            <el-form-item prop="num" :label="$fanyi('验证码')">
              <div class="formCodeInput flexAndCenter">
                <!--                <el-select v-model="value" style="margin-right: 10px">-->
                <!--                  <el-option-->
                <!--                      v-for="item in options"-->
                <!--                      :key="item.value"-->
                <!--                      :label="item.label"-->
                <!--                      :value="item.value">-->
                <!--                  </el-option>-->
                <!--                </el-select>-->
                <div class="text">{{ $fanyi("手机") }}</div>
                <input v-bind:class="{istrueInput: !!formInputTrue.num}"
                       class="iptSmall" style="width: 120px"
                       @focus="validateField('num')"
                       @blur="validateField('num')"
                       v-model="formregister.num"/>
                <el-button v-show="show" type="primary" @click.prevent="showImageDialog">{{
                    $fanyi('获取验证码')
                  }}
                </el-button>
                <el-button type="primary" v-show="!show">{{ Countdown }} S</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="registerFooter">
          <div class="userAgreementContainer">
            <div class="checkboxContainer">
              <input type="checkbox" v-model="agreement"/>
              <div class="flexAndCenter userAgreementLink">RAKUMARTの<span
                  @click="updateComponentName(1)">{{
                  $fanyi('用户协议')
                }}</span>&<span @click="updateComponentName(2)">{{ $fanyi('隐私政策') }}</span>
              </div>
            </div>
            <el-button type="primary" @click.prevent="getNext">{{ $fanyi('同意以上协议，进入下一步') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二步页面 -->
    <div class="register" v-if="step == 2" v-loading="loading">
      <div class="logo">
        <img alt="" src="../../assets/1688/commoditySearchPageLogo.png">
      </div>
      <div class="registerFirstStep" style="min-height: 365px;">
        <div class="flex">
          <el-form
              class="firStepForm"
              :model="formregister"
              :rules="formRules"
              ref="formRef"
              label-position="top">
            <el-form-item prop="username" :label="$fanyi('登录名')">
              <div class="showregisterInfo">
                {{ formregister.username }}
              </div>
            </el-form-item>
            <el-form-item prop="last_name" :label="$fanyi('姓氏')">
              <div class="showregisterInfo">
                {{ formregister.last_name }}
              </div>
            </el-form-item>
            <el-form-item :label="$fanyi('名称')" prop="realname">
              <div class="showregisterInfo">
                {{ formregister.realname }}
              </div>
            </el-form-item>
            <el-form-item prop="password" :label="$fanyi('密码')">
              <div class="formInput">
                <el-input :type="inputType" class="ipt passWordInputBox"
                          disabled style="position: relative"
                          v-model="formregister.password"/>
                <img :src="passwordIcon" style="width: 25px;height:15px" @click="updatePasswordIcon">
              </div>
            </el-form-item>
          </el-form>
          <el-form
              class="firStepForm"
              :model="formregister"
              :rules="formRules"
              ref="newFormRef"
              label-position="top"
              style="padding:59px 0 0 160px;">
            <el-form-item :label="$fanyi('公司名')">
              <div class="showregisterInfo">
                {{ formregister.company }}
              </div>
            </el-form-item>
            <el-form-item :label="$fanyi('座机')">
              <div class="showregisterInfo">
                {{ formregister.tel }}
              </div>
            </el-form-item>
            <el-form-item prop="email" :label="$fanyi('邮箱')">
              <div class="showregisterInfo">
                {{ formregister.email }}
              </div>
            </el-form-item>
            <el-form-item prop="mobile" :label="$fanyi('手机')">
              <div class="showregisterInfo">
                {{ formregister.mobile }}
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="display: flex;justify-content: center;padding-bottom: 70px">
          <div class="flexAndCenter twoStepButtonList">
            <div @click="step--" class="goBackDiv">{{ $fanyi('返回重新编辑') }}</div>
            <el-button type="primary" @click.prevent="formRegister()">{{ $fanyi('下一步') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="fooot">
      <Foot></Foot>
    </div>
    <el-dialog
        class="el-dialog-cus"
        :visible.sync="visible"
        width="1000px">
      <component :is="componentName"/>
    </el-dialog>
    <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        width="580px"
    >
      <imageDialog ref="imageDialogs" :img="img" @close="dialogVisible=false;" @submit="sendCode"/>
    </el-dialog>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import imageDialog from "@/components/public/imageDialog.vue"
import membershipUtilizationProtocol from './components/membershipUtilizationProtocol.vue'
import privacyPolicy from './components/privacyPolicy'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== this.formregister.password) {
        callback(new Error(this.$fanyi("输入密码和前一次输入的不一致")));
      } else {
        callback();
      }
    };
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      if (value !== '' && value != undefined) {
        if (rule.field === 'username' && !isNaN(value)) {
          callback(new Error(this.$fanyi("登录名不能全由数字组成")));
        } else {
          if (rule.field === 'mobile' && isNaN(value)) {
            callback(new Error(this.$fanyi("手机号输入错误")));
            return
          }
          this.$api.checkRegisterField({
            field: rule.field,
            value: value,
          }).then((res) => {
            if (res.success == false) {
              if (res.msg.indexOf('已被使用') === -1) {
                callback(new Error(this.$fanyi(res.msg)));
              } else {
                switch (rule.field) {
                  case 'mobile':
                    callback(new Error(this.$fanyi("手机号已被使用")));
                    break;
                  case 'email':
                    callback(new Error(this.$fanyi("邮箱已被使用")));
                    break;
                  default:
                    callback(new Error(this.$fanyi("已被使用")));
                    break;
                }
              }
            } else {
              callback();
            }
          });
        }
      }
    };
    // 对比验证码
    var ContrastVerificationCode = (rule, value, callback) => {
      this.$api.checkVerificationCode({
        number: this.value == 1 ? this.formregister.mobile : this.formregister.email,
        code: value,
      }).then((res) => {
        if (res.success == false) {
          this.regcode = false;
          callback(new Error(this.$fanyi("验证码错误")));
        } else {
          this.regcode = true;
          callback();
        }
      });
    };
    return {
      loading: false,
      visible: false,
      componentName: '',
      showPassword: true,
      dialogVisible: false,
      img: '',
      regcode: false, //验证码是否错误
      step: 1,
      inputType: 'password',
      passwordStatus: false,
      passwordIcon: require('../../assets/mima-2.png'),
      formInputTrue: {},
      // 注册表单
      formregister: {
        username: '',
        email: ''
      },
      value: '1',
      options: [{
        value: '1',
        label: this.$fanyi("手机")
      }, {
        value: '2',
        label: this.$fanyi("邮箱")
      }],
      agreement: false,
      // 表单验证
      formRules: {
        realname: [
          {
            required: true,
            message: this.$fanyi("请输入名称"),
            trigger: "blur",
          },
          {min: 1, max: 24, message: this.$fanyi("长度在 1 到 24 个字符"), trigger: 'blur'}
        ],
        user_id_card: [
          {
            required: true,
            message: this.$fanyi("请输入CIF/NIF"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: this.$fanyi("请输入登录名"),
            trigger: "blur",
          },
          {min: 2, max: 24, message: this.$fanyi("长度在 2 到 24 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: "blur",
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$fanyi("请输入姓氏"),
            trigger: "blur",
          },
          {min: 1, max: 24, message: this.$fanyi("长度在 1 到 24 个字符"), trigger: 'blur'},
        ],
        mobile: [
          {
            required: true,
            message: this.$fanyi("请输入手机号"),
            trigger: "blur",
          },
          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
        num: [
          {
            required: true,
            message: this.$fanyi("请填写验证码"),
            trigger: "blur",
          },
          {
            validator: ContrastVerificationCode,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: "blur",
          },
          {
            pattern: /^.+@.+$/i,
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            min: 6, max: 16,
            message: this.$fanyi("请输入 6 到 16 位的密码,不支持空格"),
            trigger: ["blur", "change"],
          },
        ],
        repassword: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
      num: "",
      show: true,
      Countdown: 60,
    };
  },
  components: {
    Foot,
    HomePageTop,
    membershipUtilizationProtocol,
    imageDialog,
    privacyPolicy
  },
  mounted() {
    if (localStorage.getItem('japan_user_token') != null) {
      window.close();
    }
  },
  methods: {
    // 手动触发表单验证
    validateField(type) {
      if (this.formregister[type])
        this.formregister[type] = this.formregister[type].trim();
      this.$refs.formRef.validateField(type, (v) => {
        if (type === 'username') {
          let index = this.formregister.username.indexOf('@');
          if (index !== -1) {
            this.formregister.username = this.formregister.username.substr(0, this.formregister.username.length - 1);
            this.$forceUpdate();
          }
        }
        if (v == "") {
          this.formInputTrue[type] = true;
        } else {
          this.formInputTrue[type] = false;
        }
        this.$forceUpdate();
      });
      this.$refs.newFormRef.validateField(type, (v) => {
        if (v == "") {
          this.formInputTrue[type] = true;
        } else {
          this.formInputTrue[type] = false;
        }
        this.$forceUpdate();
      });
    },
    // 从第一步到第二步
    getNext() {
      if (!this.agreement) return this.$message.error(this.$fanyi("使用条款和隐私政策还没有被同意。"));
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$refs.newFormRef.validate((valid) => {
            if (valid) {
              this.step = 2;
            }
          });
        }
      });
    },
    // 确认注册
    formRegister() {
      let gk = null;
      if (localStorage.getItem('gkValue')) {
        gk = localStorage.getItem('gkValue');
      }
      let list = {
        username: this.formregister.username, //登录名
        password: this.formregister.password, //密码
        realname: this.formregister.realname, //真实姓名
        email: this.formregister.email, //邮箱
        mobile: this.formregister.mobile + '', //手机号
        tel: this.formregister.tel,
        code: this.formregister.num,
        last_name: this.formregister.last_name,
        company: this.formregister.company,
        client_tool: 1,
        gk: gk != null ? gk : undefined,
        invite: this.$route.query.invite
      };
      // 假如是讲师系统推荐链接进入就把邀请码也放进去
      if (this.$route.query.promote) {
        list.promote = this.$route.query.promote;
      }
      this.loading = true;
      this.$api.register(list).then((res) => {
        this.loading = false;
        if (res.code != 0) {
          this.$confirm(
              this.$fanyi(res.msg),
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                cancelButtonText: this.$fanyi('取消'), //取消
                type: "warning",
                center: true,
                dangerouslyUseHTMLString: true,
              }
          ).then(() => {
          }).catch(() => {
          });
          return
        }
        window.close();
        window.open('/registerSuccess')
      });
    },
    //更新输入框密码展示状态
    updatePasswordIcon() {
      this.passwordStatus = !this.passwordStatus;
      this.passwordStatus === true ? this.inputType = 'text' : this.inputType = 'password';
      this.passwordStatus === true ? this.passwordIcon = require('../../assets/mima-1.png') : this.passwordIcon = require('../../assets/mima-2.png');
    },
    //登录名过滤@符
    usernameChannelInputLimit() {
      this.formregister.username = this.formregister.username.replace('@', '');
    },
    //密码过滤
    passwordChange(e) {
      e = e.trim();
      const reg = /[\u4e00-\u9fa5]/g
      this.formregister.password = e.replace(reg, '')
      this.$forceUpdate();
    },
    //确认密码过滤
    repasswordChange(e) {
      e = e.trim();
      const reg = /[\u4e00-\u9fa5]/g
      this.formregister.repassword = e.replace(reg, '')
      this.$forceUpdate();
    },
    // 短信验证
    showImageDialog() {
      if (this.value === '1') {
        this.$refs.newFormRef.validateField('mobile', (v) => {
          if (v == "") {
            this.formInputTrue['mobile'] = true;
            let code = ''
            for (var i = 0; i < 7; i++) {
              code += parseInt(Math.random() * 10);
            }
            let obj1 = {
              number: this.value == 1 ? this.formregister.mobile : this.formregister.email,
            }
            this.$api.getCodeImg(obj1, code).then((res) => {
              this.img = res.data.image;
              this.dialogVisible = true;
            });
          }
          this.$forceUpdate();
        });
      } else {
        this.$refs.newFormRef.validateField('email', (v) => {
          if (v == "") {
            this.formInputTrue['email'] = true;
            let code = ''
            for (var i = 0; i < 7; i++) {
              code += parseInt(Math.random() * 10);
            }
            let obj1 = {
              number: this.value == 1 ? this.formregister.mobile : this.formregister.email,
            }
            this.$api.getCodeImg(obj1, code).then((res) => {
              this.img = res.data.image;
              this.dialogVisible = true;
            });
          }
          this.$forceUpdate();
        });
      }
    },
    //确认发送短信
    sendCode(val) {
      let list = {
        number: this.value == 1 ? this.formregister.mobile : this.formregister.email,
        captcha: val
      };
      this.$api.sendVerificationCode(list).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg)
          return
        }
        this.show = false;
        this.Countdown = 60;
        this.show = true;
        // 60秒倒计时
        this.show = false;
        this.dialogVisible = false;
        this.$refs.imageDialogs.code = '';
        var timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    //更新展示的内容
    updateComponentName(val) {
      val == 1 ? this.componentName = 'membershipUtilizationProtocol' : this.componentName = 'privacyPolicy';
      this.visible = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin";

.main {
  background-color: white;
  width: $pageWidth;
  margin: 10px auto 0;
  border-radius: 6px 6px 0 0;
}

.header {
  height: 30px;
  width: 100%;
  background: #eee;
}

/deep/ .input__inner {
  border-radius: 0;
}

/deep/ .el-dialog {
  box-sizing: border-box;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__header {
  padding: 25px;
}

.logo-box {
  height: 73px;
  overflow: hidden;
  border-bottom: 1px solid #DFDFDF;
  margin-top: 20px;

  .fenge {
    margin-left: 22px;
  }

  h2 {
    line-height: 73px;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-left: 25px;
  }

  .login {
    margin: 30px 50px 0 0;

    &.fr {
      $loginIconSize: 14px;

      .oicon {
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        font-size: 12px;
        line-height: $loginIconSize;
        font-weight: 400;
        color: #ffffff;
        width: $loginIconSize;
        height: $loginIconSize;
        background: $homePageSubjectColor;
        border-radius: 50%;
      }
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #999;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      color: #404040;
    }
  }
}

.twoStepButtonList {
  margin-top: 37px;

  /deep/ .el-button {
    width: 150px;
    height: 38px;
    background: #B4272B;
    border-radius: 3px;
    text-align: center;
    border: none;
    padding: 0;
  }

  .goBackDiv {
    width: 150px;
    height: 38px;
    background: transparent;
    border-radius: 3px;
    text-align: center;
    border: 1px solid #B4272B;
    padding: 0;
    line-height: 38px;
    font-size: 14px;
    color: #B4272B;
    margin-right: 20px;
    cursor: pointer;
  }

  .el-button + .el-button {
    margin-left: 20px;
  }
}

.register {
  width: 1400px;
  margin: 0 auto 30px;
  padding-bottom: 80px;
  background: #ffffff;

  .registerFirstStep {
    width: 1000px;
    min-height: 680px;
    background: #ffffff;
    border: 1px solid #DFDFDF;
    margin: 0 auto;

    .tiShi {
      margin-bottom: 33px;

      p {
        display: block;
        height: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #7a7a7a;
        line-height: 20px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .registerFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .userAgreementContainer {
      margin-top: 20px;

      .checkboxContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;

        input {
          min-width: 16px;
          margin-right: 5px;
        }
      }

      .userAgreementLink {
        font-size: 12px;
        text-align: center;

        span {
          color: #B4272B;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/ .el-checkbox {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
      }

      /deep/ .el-button {
        width: 320px;
        height: 38px;
        background: #B4272B;
        border-radius: 3px;
        text-align: center;
        border: none;
        padding: 0;
      }

      .el-checkbox__inner:hover {
        color: #DCDFE6;
      }
    }
  }

  .firstStepTitle {
    margin-bottom: 30px;

    .xuYaoXinXi {
      height: 21px;
      font-size: 18px;

      font-weight: 600;
      color: #2f2f2f;
      margin-bottom: 15px;
    }
  }

  .firStepForm {
    padding: 59px 0 0 100px;

    /deep/ .el-form-item__error {
      top: 40px;
      margin-left: 9px;
    }

    /deep/ .el-form-item__label {
      padding: 0;
    }

    .formInput {
      position: relative;

      .areaCode {
        width: 46px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #DFDFDF;
        font-size: 14px;
        color: #333333;
        margin-right: 6px;
        text-align: center;
      }

      input {
        width: 320px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 3px;
        padding: 0 10px;
        transition: 0.4s;

        &::placeholder {
          color: #b6b6b6;
        }

        &:focus {
          border: 1px solid #B4272B;
        }

        &.istrueInput {
          position: relative;
        }
      }

      /deep/ .el-input__inner {
        width: 320px;
        height: 38px;
      }

      /deep/ .el-input__suffix {
        right: 10px;
      }

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      /deep/ .el-input.is-disabled .el-input__inner {
        background-color: #F5F7FA;
        color: #333;
      }
    }

    .mobileIconWen, .emailIconWen {
      .iconWen {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #dfdfdf;
        color: #dfdfdf;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;
        position: absolute;
        top: -31px;
        left: 105px;
      }
    }

    .emailIconWen {
      .iconWen {
        left: 115px;
      }
    }

    .newFormInput {
      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 40px;
      }
    }

    .formCodeInput {
      input {
        width: 210px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 3px;
        padding: 0 10px;
        transition: 0.4s;

        &::placeholder {
          color: #b6b6b6;
        }

        &:focus {
          border: 1px solid #B4272B;
        }

        &.istrueInput {
          position: relative;
        }
      }

      .text {
        width: 130px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #dedede;
        text-align: center;
        border-radius: 3px;
        margin-right: 10px;
        line-height: 38px;
      }

      /deep/ .el-button {
        width: 100px;
        height: 38px;
        background: #B4272B;
        border-radius: 3px;
        text-align: center;
        border: none;
        padding: 0;
        margin-left: 10px;
      }

      /deep/ .el-select {
        width: 130px;

        .el-input__inner {
          height: 38px;
          border-radius: 3px;
        }
      }
    }


    > ul {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;

      > li {
        width: 379px;
        display: flex;
        flex-direction: column;
        // 表单标题样式
        /deep/ .el-form-item__label {
          padding: 0;
          text-align: left;
          float: none;
          display: block;
          width: 100%;
          line-height: 18px;
          height: 18px;
          margin-bottom: 10px;

          &::before {
            display: none;
          }
        }

        /deep/ .elistrueInput .el-input__inner {
          background: #f3f6fc;
          border: 1px solid #abbbd2;
          border-radius: 6px;
          position: relative;
        }

        .gou {
          position: absolute;
          font-weight: bold;
          right: 13px;
          top: 3px;
          color: #417db4;

          &.regCodegou {
            right: 222px;
          }
        }

        .xuanZeGuoJia {
          width: 100%;
          height: 48px;
          background: #f3f6fc;
          border-radius: 6px;

          /deep/ .el-input__inner {
            width: 100%;
            height: 48px;
            background: #f3f6fc;
            border: 1px solid #abbbd2;
            border-radius: 6px;
          }

          /deep/ .el-icon-arrow-up {
            color: $homePageSubjectColor;
            font-weight: bold;
            font-size: 15px;
          }
        }
      }

      .guoJiaQuHao {
        position: relative;

        .shouJIHaoCon {
          display: flex;
          align-items: center;

          .quHao {
            width: 58px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            &.istrueInput {

              position: relative;
            }
          }

          .oneRowTowName {
            position: absolute;
            left: 69px;
            top: -39px;
            font-size: 14px;
            color: #606266;
          }

          input {
            width: 312px;
          }
        }
      }

      .yanzhengma {
        .yanzhengmaBox {
          display: flex;
          align-items: center;

          input {
            width: 167px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;

            &.istrueInput {

              position: relative;
            }
          }

          button {
            margin-left: 80px;
            width: 195px;
            height: 48px;
            background: $homePageSubjectColor;
            border: 1px solid $homePageSubjectColor;
            border-radius: 6px;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }

      .xieYi {
        width: 379px;
        display: flex;
        align-items: center;

        .fuWuXieYi {
          display: flex;
          align-items: flex-start;

          /deep/ .el-checkbox {
            display: flex;
            align-items: flex-start;
          }

          a {
            width: 161px;
            height: 41px;
            font-size: 12px;
            font-weight: 300;
            color: #2f2f2f;
            line-height: 14px;
            margin: 0 20px 0 10px;
            position: relative;
            top: -2px;
          }

          button {
            width: 170px;
            height: 48px;
            background: #47bd9b;
            border: 1px solid #47bd9b;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      .xiaYiBuHuoFanHui {
        .caoZuo {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            width: 45%;
            height: 48px;
            background: #47bd9b;
            border: 1px solid #47bd9b;
            border-radius: 6px;
            color: white;
          }

          .shangYiBu {
            background: $homePageSubjectColor;
            border-color: $homePageSubjectColor;
          }

          .xiaYiBu {
            background: #47bd9b;
          }
        }
      }
    }

    .showregisterInfo {
      width: 320px;
      height: 38px;
      background-color: #F5F7FA;
      border: 1px solid #dedede;
      border-radius: 3px;
      padding: 0 10px;
    }

    .newShowregisterInfo {
      width: 210px;
      height: 38px;
      background-color: #F5F7FA;
      border: 1px solid #dedede;
      border-radius: 3px;
      padding: 0 10px;
    }
  }

  // 表单错误提示样式
}

.stepGroup {
  width: 1400px;
  padding: 34px 0 36px;
  margin: 0 auto;
  background-color: #fff;

  .step {
    width: 1000px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .item {
      color: #999;
      text-align: center;

      span {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-color: #DFDFDF;
        line-height: 25px;
        color: white;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .newIsStepBorder {
      width: 330px;
      height: 6px;
      background: #DFDFDF;
      border-radius: 3px;
      margin-top: 6px;
    }

    .isStepBorder {
      width: 330px;
      height: 6px;
      border-radius: 3px;
      margin-top: 6px;
      background: #B4272B;
    }

    .isStep {
      color: #B4272B;

      span {
        background-color: #B4272B;
        color: #fff;
      }
    }

    .successStep {
      span {
        background-color: #47bd9b;
        outline-color: #c0f1e3;
        color: #fff;
      }
    }

    .border {
      flex: 1;
      height: 1px;
      background-color: #d7d7d7;
      margin: 14px 28px 0;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.registerInformation {
  width: 1400px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  padding: 0 50px 50px;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;

  > .registerInformationCon {
    width: 900px;
    height: 480px;
    border: 1px solid #d7d7d7;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    padding: 50px;
    flex-direction: column;
    background-color: white;

    > .registerInformationTitle {
      font-size: 24px;
      color: #000000;
      line-height: 24px;
      margin-bottom: 60px;
    }

    > .registerInformationOpt {
      margin-bottom: 20px;

      > label {
        color: #000000;

        font-size: 14px;
        font-weight: bold;
        width: 314px;
        display: inline-block;
        text-align: right;
        margin-right: 40px;
      }

      > span {
        font-size: 14px;
      }

      &:last-child {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        > button {
          padding: 0 12px;
          height: 26px;
          border-radius: 6px;
          border: 1px solid $homePageSubjectColor;
          background-color: white;
          color: $homePageSubjectColor;
          margin-right: 20px;

          &:last-child {
            background-color: $homePageSubjectColor;
            color: white;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.registration_complete {
  width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding: 0 80px 200px;
  background-color: white;

  > .registration_completeCon {
    margin: 0 auto;
    width: 1000px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d7d7d7;

    .registration_completeConOpt {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .successImg {
        width: 80px;
        height: 80px
      }

      span {
        font-size: 14px;

        line-height: 20px;
      }

      button {
        min-width: 195px;
        background: $homePageSubjectColor;
        border-radius: 6px;
        padding: 0 40px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        height: 54px;
      }
    }

    .newRegistration_completeConOpt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 18px;
        color: #333;
      }
    }
  }
}

/deep/ .el-checkbox__input {
  height: 16px;
}

.ologin {
  cursor: pointer;
  display: inline-block;
  font-size: 55px;
  color: $homePageSubjectColor;
  margin-bottom: 10px;
}

.passWordInputBox {
  /deep/ .el-input__inner {
    height: 48px;
  }

}

.allDataShow {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #999999;
  z-index: 999;
}

// 输入框验证失败样式
/deep/ .is-error input {
  border-color: #B4272B !important;
  background: #FCF2F2 !important;
}

input[type="checkbox"]:checked {
  background: #b4272b;
  border: none;
}

// 句子太长的提示语句样式
.regerror {
  /deep/ .el-form-item__error {
    top: 90% !important;
  }
}

.fooot {

}
</style>
